<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-col cols="12" class="add-patient align-self-start" align="center">
      <v-col cols="12" class="d-flex justify-end align-center">
        <v-btn text @click="closeAddDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-col>
      <p class="text-h5 text-sm-h5 text-md-h5 primary--text text-center">
        {{ $t('therapist.add-patient') }}
      </p>
      <v-form @submit.prevent="validate">
        <v-container class="justify-center add-patient">
          <v-col>
            <!-- <v-col cols="11" sm="10" md="2" lg="2" xl="2"> -->
            <v-col cols="10">
              <v-text-field
                v-model="studentId"
                label="ID"
                id="id"
                :error-messages="numberErrors"
                dense
                outlined
                background-color="white"
                @change="$v.studentId.$touch()"
                @blur="$v.studentId.$touch()"
                class="studentAdd"
              >
              </v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="firstname"
                :error-messages="firstnameErrors"
                label="Vorname"
                id="firstname"
                outlined
                dense
                required
                background-color="white"
                @input="$v.firstname.$touch()"
                @blur="$v.firstname.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="10">
              <v-text-field
                v-model="lastname"
                :error-messages="lastnameErrors"
                label="Nachname"
                id="lastname"
                outlined
                dense
                required
                background-color="white"
                @input="$v.lastname.$touch()"
                @blur="$v.lastname.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-select
                v-model="grade"
                :error-messages="gradeErrors"
                label="Klasse"
                id="grade"
                :items="grades"
                outlined
                dense
                required
                background-color="white"
                @input="$v.grade.$touch()"
                @blur="$v.grade.$touch()"
              ></v-select>
            </v-col>
            <!-- <template v-if="isParent">
              <v-col cols="10" class="ma-0 pt-0">
                <v-col
                  cols="12"
                  class="d-flex justify-space-between align-center py-0"
                >
                  <p class="ma-0 mr-4">{{ $t('formSwitch.enableFormA') }}</p>
                  <v-switch v-model="enableA" inset class="pa-0"></v-switch>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between align-center py-0"
                >
                  <p class="ma-0 mr-4">{{ $t('formSwitch.enableFormB') }}</p>
                  <v-switch v-model="enableB" inset class=""></v-switch>
                </v-col>
              </v-col>
            </template> -->
            <v-col cols="10">
              <v-btn
                :loading="loading"
                color="primary"
                class="mr-4"
                id="add-patient-button"
                type="submit"
              >
                <v-icon left dark>mdi-account-plus </v-icon>
                <p class="pa-0 ma-0" align="center">Zugang erstellen</p>
              </v-btn>
            </v-col>
          </v-col>
          <!-- <AdminSnackbar
            :snackbar="snackbar"
            :isSuccess="isSuccess"
            :message="message"
          /> -->
        </v-container>
      </v-form>
    </v-col>
  </v-dialog>
</template>

<script lang="ts">
import SchoolModule from '@/store/modules/SchoolModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import {
  minLength,
  minValue,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import AdminSnackbar from '../../base/AdminSnackbar.vue';

import { Role } from '@/api/types';
import UserModule from '@/store/modules/UserModule';
import { getDefaultPassword, getNameAbbr } from '@/utils/common';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    studentId: { required, numeric, minValue: minValue(1) },
    grade: { required },
  },
  components: { AdminSnackbar },
})
export default class AddPatientAccount extends Vue {
  @Prop() dialog!: boolean;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  firstname = '';
  lastname = '';
  email = '';
  grade = '';
  studentId: number | null = null;
  enableA = false;
  enableB = false;

  message = 'Schüler/in erfolgreich hinzugefügt';

  snackbar = false;
  isSuccess = false;
  loading = false;

  get text(): string {
    return this.isSuccess
      ? 'Schüler/in erfolgreich hinzugefügt.'
      : 'Leider ist etwas schiefgelaufen.';
  }

  get grades(): string[] {
    return this.schoolModule.classes;
  }

  get schoolSuffix(): string {
    return `_${this.schoolId}`;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  get isParent(): boolean {
    return this.userModule.currentUser.role === Role.parent;
  }

  @Watch('dialog')
  onDialogChange(): void {
    if (this.dialog) {
      this.$v.$reset();
    }
  }

  @Emit()
  closeAddDialog(): void {
    console.log();
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const DEFAULT_PASSWORD = getDefaultPassword(
          this.firstname,
          this.lastname,
        );
        const nameAbbr = getNameAbbr(this.firstname, this.lastname, true);
        const studentUsername = `${nameAbbr}_${this.schoolId}`;

        const payload = this.isParent
          ? {
              username: studentUsername,
              firstname: this.firstname.trim(),
              lastname: this.lastname.trim(),
              studentId: parseInt(this.studentId!.toString()),
              grade: this.grade,
              schoolId: this.schoolId.toString(),
              role: Role.student,
              password: DEFAULT_PASSWORD,
              studentPassword: DEFAULT_PASSWORD,
              enableA: this.enableA,
              enableB: this.enableB,
            }
          : {
              username: studentUsername,
              firstname: this.firstname,
              lastname: this.lastname,
              //INFO strange bug: is number but backend received string => extra casting
              studentId: parseInt(this.studentId!.toString()),
              grade: this.grade,
              schoolId: this.schoolId.toString(),
              role: Role.student,
              password: DEFAULT_PASSWORD,
              studentPassword: DEFAULT_PASSWORD,
            };

        await this.schoolModule.addUser(payload);

        this.snackbarModule.showMessage({
          message: this.isTherapist
            ? this.$i18n.t('snackbar.patients.created.success').toString()
            : this.$i18n.t('snackbar.students.created.success').toString(),
          isSuccess: true,
        });
        // INFO emit necessary => watcher in dashboard for selectedClass that is updated right before
        this.addedStudent();
        this.clear();
      } catch (error) {
        this.snackbarModule.showMessage({
          message: this.isTherapist
            ? this.$i18n.t('snackbar.patients.created.error').toString()
            : this.$i18n.t('snackbar.students.created.error').toString(),
          isSuccess: false,
        });
      } finally {
        this.loading = false;
      }
      this.closeAddDialog();
    }
  }

  @Emit()
  addedStudent() {
    console.log();
  }

  clear(): void {
    this.$v.$reset();
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.studentId = null;
    this.grade = '';
    this.enableA = false;
    this.enableB = false;
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.studentId.$dirty) return errors;
    !this.$v.studentId.numeric && errors.push('Nur Zahlen erlaubt!');
    !this.$v.studentId.minValue && errors.push('Nur Zahlen > 0 erlaubt!');
    !this.$v.studentId.required && errors.push('Bitte ID angeben.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }
  get gradeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('Bitte eine Klasse auswählen.');
    return errors;
  }

  showSnackbar(message: string, isSuccess: boolean) {
    this.snackbar = true;
    this.isSuccess = isSuccess;
    this.message = message;
    setTimeout(async () => {
      this.snackbar = false;
    }, 2500);
  }
}
</script>

<style scoped lang="scss">
.add-patient {
  background: $background;
}
.form-input >>> .v-input__slot::after {
  border-color: red !important;
}

.form-input >>> .v-input__slot::before {
  border-color: red !important;
}
.form-input >>> .error--text {
  color: red !important;
}
.form-input >>> input {
  caret-color: red !important;
}
</style>
