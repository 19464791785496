<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-col cols="12" class="add-dialog align-self-start" align="center">
      <v-col cols="12" class="d-flex justify-end align-center">
        <v-btn text @click="closeAddDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-col>
      <p class="text-h5 text-sm-h5 text-md-h5 primary--text text-center">
        {{ $t('teacher.manual-create') }}
      </p>
      <v-form @submit.prevent="validate">
        <v-container class="justify-center">
          <v-col>
            <!--  <v-col cols="12">
              <v-text-field
                v-model="studentId"
                label="ID"
                :error-messages="numberErrors"
                dense
                solo
                background-color="white"
                @change="$v.studentId.$touch()"
                @blur="$v.studentId.$touch()"
                class="studentAdd"
              >
              </v-text-field>
            </v-col> -->
            <!-- <v-col cols="10" sm="10" md="3" lg="3" xl="3"> -->
            <v-col cols="12">
              <v-text-field
                class="addStudent"
                v-model="firstname"
                :error-messages="firstnameErrors"
                label="Vorname"
                id="firstname"
                solo
                dense
                required
                background-color="white"
                @input="$v.firstname.$touch()"
                @blur="$v.firstname.$touch()"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="10" sm="10" md="3" lg="3" xl="3"> -->
            <v-col cols="12">
              <v-text-field
                class="addStudent"
                v-model="lastname"
                :error-messages="lastnameErrors"
                label="Nachname"
                id="lastname"
                solo
                dense
                required
                background-color="white"
                @input="$v.lastname.$touch()"
                @blur="$v.lastname.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-btn
                id="add-student-button"
                color="primary"
                :loading="loading"
                type="submit"
              >
                <v-icon left dark>mdi-account-plus </v-icon>
                <p class="pa-0 ma-0 mt-1" align="center">
                  {{ $t('admin.create-account') }}
                </p>
              </v-btn>
            </v-col>
          </v-col>
        </v-container>
      </v-form>
    </v-col>
  </v-dialog>
</template>

<script lang="ts">
import { Role, UserDto } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { getDefaultPassword, getNameAbbr } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
  },
  components: {},
})
export default class AddStudentAccount extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() dialog!: boolean;
  @Prop() grade!: string;
  @Prop() students!: UserDto[];
  @Prop() ids!: number[];

  MAX_STUDENTS = 30;
  firstname = '';
  lastname = '';
  email = '';
  cStudentId: number | null = null;
  loading = false;

  get numberOfStudents(): number {
    return this.schoolModule.myStudents.length;
  }

  get schoolSuffix(): string {
    return `_${this.schoolId}`;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  get isTherapist(): boolean {
    return this.userModule.currentUser.role === Role.therapist;
  }

  @Watch('dialog')
  onDialogChange(): void {
    if (this.dialog) {
      this.$v.$reset();
    }
  }

  @Emit()
  closeAddDialog(): void {
    this.clear();
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        if (this.numberOfStudents === this.MAX_STUDENTS) {
          throw new Error('reached student maximum');
        }
        const DEFAULT_PASSWORD = getDefaultPassword(
          this.firstname,
          this.lastname,
        );
        const nameAbbr = getNameAbbr(this.firstname, this.lastname);
        const studentUsername = `${nameAbbr}${this.grade}${this.schoolSuffix}`;

        await this.schoolModule.addUser({
          username: studentUsername,
          firstname: this.firstname.trim(),
          lastname: this.lastname.trim(),
          //INFO strange bug: is number but backend received string => extra casting
          studentId: parseInt((this.numberOfStudents + 1).toString()),
          grade: this.grade,
          schoolId: this.schoolId.toString(),
          role: Role.student,
          password: DEFAULT_PASSWORD,
          studentPassword: DEFAULT_PASSWORD,
        });
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.students.created.success').toString(),
          isSuccess: true,
        });

        this.addedStudent();
        this.clear();
      } catch (error: any) {
        if (error?.message === 'reached student maximum') {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.students.created.max').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('snackbar.students.created.error').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
      this.closeAddDialog();
    }
  }

  @Emit()
  addedStudent() {
    console.log();
  }

  clear(): void {
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.cStudentId = null;
    this.$v.$reset();
  }

  get numberErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.studentId.$dirty) return errors;
    !this.$v.studentId.numeric && errors.push('Nur Zahlen erlaubt!');
    !this.$v.studentId.minValue && errors.push('Nur Zahlen > 0 erlaubt!');
    !this.$v.studentId.required && errors.push('Bitte ID angeben.');
    !this.$v.studentId.isUnique && errors.push('ID schon vergeben!');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }
}
</script>

<style scoped lang="scss">
.add-dialog {
  background-color: $background;
}
</style>

<style lang="scss">
.addStudent.error--text,
.addStudent.error--text .v-text-field__slot input,
.addStudent.error--text .v-label.error--text,
.addStudent.v-text-field--solo .v-input__slot,
.addStudent .error--text .v-messages__message {
  color: $lesikus-error-color;
  caret-color: $lesikus-error-color;
}

.addStudent.v-text-field--solo .v-input__slot {
  box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>
