<template>
  <v-expansion-panels
    accordion
    :style="{ 'max-width': 100 + '%', 'background-color': '#f6fafd' }"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <p class="ma-0 pa-0 grey--text text--darken-3">
          {{ $t('general-info') }}
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row class="d-flex justify-center ma-0">
          <v-col cols="10" class="grey--text text--darken-3">
            <ol>
              <li v-for="n in keys" :key="n">
                <p
                  class="body-1"
                  v-html="$t(`${title}.dashboard.info.${n}`)"
                ></p>
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <p class="ma-0 pa-0 grey--text text--darken-3">
          {{ $t(`${title}.create`) }}
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row class="d-flex justify-center">
          <v-col cols="10" class="grey--text text--darken-3">
            <p class="text-body-1">
              {{ $t(`${title}.dashboard.create-info.title`) }}
            </p>
            <p
              class="text-subtitle-1 font-weight-bold"
              v-html="$t(`${title}.dashboard.create-info.manually.title`)"
            ></p>
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.create-info.manually.info`)"
            ></p>
            <p
              class="text-subtitle-1 font-weight-bold"
              v-html="$t(`${title}.dashboard.create-info.upload.title`)"
            ></p>
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.create-info.upload.info`)"
            ></p>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <p class="ma-0 pa-0 grey--text text--darken-3">
          {{ $t(`${title}.manage-classList`) }}
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row class="d-flex justify-center ma-0 grey--text text--darken-3">
          <v-col cols="10">
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.classList-info.edit`)"
            ></p>
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.classList-info.delete`)"
            ></p>
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.classList-info.code`)"
            ></p>
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.classList-info.info`)"
            ></p>
            <p
              class="text-body-1"
              v-html="$t(`${title}.dashboard.classList-info.end`)"
            ></p>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="!isTherapist">
      <v-expansion-panel-header>
        <p class="ma-0 pa-0 grey--text text--darken-3">
          {{ $t('classLevel.label') }}
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row class="d-flex justify-center ma-0 grey--text text--darken-3">
          <v-col cols="10">
            <p
              class="text-body-1"
              v-html="$t('classLevel.evaluation-info')"
            ></p>
            <p
              class="text-subtitle-1 font-weight-bold"
              v-html="$t('classLevel.level-all')"
            ></p>
            <p class="text-body-1" v-html="$t('classLevel.warning')"></p>
            <p
              class="text-subtitle-1 font-weight-bold mt-4"
              v-html="$t('classLevel.mixed-title')"
            ></p>
            <p class="text-body-1" v-html="$t('classLevel.mixed')"></p>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="!isTherapist">
      <v-expansion-panel-header>
        <p class="ma-0 pa-0 grey--text text--darken-3">
          {{ $t('manageTests.title') }}
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row class="d-flex justify-center ma-0 grey--text text--darken-3">
          <v-col cols="10">
            <p class="text-body-1" v-html="$t('manageTests.info')"></p>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <!--TODO add evaluation info-->
    <v-expansion-panel v-if="!isTherapist">
      <v-expansion-panel-header>
        <p class="ma-0 pa-0 grey--text text--darken-3">
          {{ $t('evaluationInfo.title') }}
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <v-row class="d-flex justify-center ma-0 grey--text text--darken-3">
          <v-col cols="10">
            <p
              class="text-h5 font-weight-bold"
              v-html="$t('evaluationInfo.pr.title')"
            ></p>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.pr.text')"
            ></div>
          </v-col>
          <v-col cols="10">
            <p
              class="text-h5 font-weight-bold mb-4"
              v-html="$t('evaluationInfo.interpretation.title')"
            ></p>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.interpretation.text')"
            ></div>
          </v-col>
          <v-col cols="10">
            <p
              class="text-h5 font-weight-bold mb-4"
              v-html="$t('evaluationInfo.results.title')"
            ></p>
            <div
              class="text-h6 font-weight-bold mb-4"
              v-html="$t('evaluationInfo.results.ut1.title')"
            ></div>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.results.ut1.text')"
            ></div>
            <div
              class="text-h6 font-weight-bold my-4"
              v-html="$t('evaluationInfo.results.ut1b.title')"
            ></div>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.results.ut1b.text')"
            ></div>
            <div
              class="text-h6 font-weight-bold my-4"
              v-html="$t('evaluationInfo.results.ut2.title')"
            ></div>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.results.ut2.text')"
            ></div>
            <div
              class="text-h6 font-weight-bold my-4"
              v-html="$t('evaluationInfo.results.ut3.title')"
            ></div>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.results.ut3.text')"
            ></div>
            <div
              class="text-h6 font-weight-bold my-4"
              v-html="$t('evaluationInfo.results.ut4.title')"
            ></div>
            <div
              class="text-body-1"
              v-html="$t('evaluationInfo.results.ut4.text')"
            ></div>
            <div
              class="text-h6 font-weight-bold my-4"
              v-html="$t('evaluationInfo.results.ut5.title')"
            ></div>
            <div
              class="text-body-1 mb-5"
              v-html="$t('evaluationInfo.results.ut5.text')"
            ></div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class GeneralInfoPanels extends Vue {
  @Prop({ default: false }) isTherapist!: boolean;

  get keys(): number {
    return this.isTherapist ? 8 : 9;
  }

  get title(): string {
    return this.isTherapist ? 'therapist' : 'teacher';
  }
}
</script>

<style scoped lang="scss"></style>
